



































































































  import { Validator } from '@/constant/Mixins';
  import { Toaster } from '@/constant/Toaster';
  import { ServerResponse } from '@/services/response.types';
  import { CityModel, ProvinceModel } from '@/store/location/types';
  import { PaymentProductMobilConfirmationModel } from '@/store/product/mobil/types';
  import { ConfirmationUserModel, ConfirmationVehicleModel, ProductBrandModel, ProductModel } from '@/store/product/types';
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Mixins } from 'vue-property-decorator';
  import { Action, State } from 'vuex-class';

  @Component({
    name: 'personalMobil',
    components: {
      PaymentOptions: () => import('@/components/payment/paymentOptions.vue'),
    },
  })
  export default class personalMobil extends Mixins(Validator) {
    @Action('CheckuserAuth', { namespace: 'auth' }) CheckuserAuth!: () => Promise<ServerResponse>;
    @Action('GetPaymentConfirmProduct', { namespace: 'product/mobil' }) GetPaymentConfirmProduct!: (quotation_id: string) => Promise<ServerResponse>;
    @Action('CheckoutProduct', { namespace: 'product/mobil' }) CheckoutProduct!: (quotation_id: string) => Promise<ServerResponse>;
    @Action('GetBrands', { namespace: 'product/mobil' }) GetBrands: (quotation_id: string) => Promise<ServerResponse>;
    @Action('GetProvince', { namespace: 'location' }) GetProvince!: (params?: any) => Promise<ServerResponse>;
    @Action('GetCity', { namespace: 'location' }) GetCity!: (params?: any) => Promise<ServerResponse>;

    @State('brands', { namespace: 'product/mobil' }) brands!: ProductBrandModel[];
    @State('province', { namespace: 'location' }) province!: ProvinceModel[];
    @State('city', { namespace: 'location' }) city!: CityModel[];
    @State('confirmation', { namespace: 'product/mobil' }) confirmation!: PaymentProductMobilConfirmationModel;

    public get user(): ConfirmationUserModel {
      return this.confirmation.user;
    }

    public get product(): ProductModel {
      return this.confirmation.product;
    }

    public get vehicle(): ConfirmationVehicleModel {
      return this.confirmation.vehicle;
    }

    public get selectedBrand(): string {
      return this.brands.filter((item) => String(item.brand_id) === String(this.vehicle.brand_id))[0]?.brand_name ?? '-';
    }

    public get selectedProvince(): string {
      return this.province.filter((item) => item.state_id === this.vehicle.state_id)[0]?.state_name ?? '-';
    }

    public get selectedCity(): string {
      return this.city.filter((item) => item.city_id === this.vehicle.city_id)[0]?.city_name ?? '-';
    }

    public showModal = false;
    public summary: { label?: string; value: any; label_class?: string; value_class?: string; row_class?: string; label_colspan?: string; value_colspan?: string; show_label?: boolean; html_value?: boolean }[] = [];
    public transaction_id: string = null;
    public confirmed = false;

    public created(): void {
      // on componenet created
    }

    public mounted(): void {
      this.GetPaymentConfirmProduct(this.$route.query.q as string).then((res) => {
        this.GetCity({ city_id: this.vehicle.city_id });
      });
      this.GetBrands(this.$route.query.q as string);
      this.GetProvince();
    }

    /**
     * OnSubmit
     */
    public OnSubmit() {
      if (this.confirmed) {
        this.CheckoutProduct(this.$route.query.q as string).then((res) => {
          // Toaster.Make(res.messages, { variant: 'success', title: 'Payment Complete!' });
          // this.$router.push({ name: 'transaction-history' });

          let summ = res.data.summary;

          this.summary = [];
          this.summary.push({ label: 'Perusahaan', value: summ.provider_name });
          this.summary.push({ label: 'Produk', value: this.product.product_name });
          this.summary.push({ label: 'Premi Dasar', value: this.formatCurrency(summ.basic_premi) });
          this.summary.push({ label: 'Premi Tambahan', value: this.formatCurrency(summ.additional_premium ?? 0) });
          this.summary.push({ label: 'Diskon', value: this.formatCurrency(summ.discount_amount ?? 0) });
          this.summary.push({ label: 'Administrasi', value: this.formatCurrency(summ.admin_fee ?? 0) });
          this.summary.push({ label: 'Total Tagihan', value: this.formatCurrency(summ.total ?? 0), row_class: 'bg-primary text-white' });
          this.summary.push({ show_label: false, value_colspan: '2', html_value: true, value: `<p><b>Alamat Pengiriman Polis</b></p><p>${summ.shipping_address}</p>` });

          this.transaction_id = res.data.enc_trx_id;

          this.showModal = true;
        });
      } else Toaster.Warning('Please Confirm by checking the checkbox!', { title: 'Need Confirmation' });
    }
  }
